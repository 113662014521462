<template>
  <div class="accordion">
    <div class="accordion__head" @click="handleToggle">
      <div class="accordion__title">{{ title }}</div>
      <div class="accordion__button">
        <svg
          width="15"
          height="9"
          viewBox="0 0 15 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.38672 8.4707C7.62402 8.4707 7.85254 8.37402 8.01074 8.19824L14.418 1.6416C14.5674 1.49219 14.6553 1.29004 14.6553 1.06152C14.6553 0.595703 14.2949 0.235352 13.8291 0.235352C13.6094 0.235352 13.3984 0.323242 13.2402 0.472656L7.39551 6.45801L1.54199 0.472656C1.38379 0.323242 1.18164 0.235352 0.944336 0.235352C0.478516 0.235352 0.118164 0.595703 0.118164 1.06152C0.118164 1.29004 0.206055 1.49219 0.364258 1.65039L6.77148 8.19824C6.94727 8.37402 7.14941 8.4707 7.38672 8.4707Z"
            fill="#636366"
          />
        </svg>
      </div>
    </div>
    <div class="accordion__body" v-if="!isCollapse">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      isCollapse: true,
    };
  },
  methods: {
    handleToggle() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  border: 1px solid #636366;
  border-radius: 10px;
  /* padding: 15px; */
  margin-bottom: 16px;
  &__title {
    color: 1px solid #000000;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    padding: 15px;
  }
  &__head {
    display: flex;
    justify-content: space-between;
  }
  &__button {
    padding: 15px;
  }
  &__body {
    padding: 12px;
    font-size: 14px;
    line-height: 22px;
    transition: 1s;
    max-height: 330px;
    overflow-y: auto;
    color: #636366;
  }
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
  height: 100%;
}

.slide-fade-leave-active {
  transition: height 0.3s ease-out;
  height: 0;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
}
</style>
