<template>
  <div class="shared-checkbox">
    <input
      type="checkbox"
      :id="name"
      :name="name"
      :checked="checked"
      @change="$emit('change', $event.target.checked)"
    />
    <label :for="name">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
    },
  },
  model: {
    event: "change",
    prop: "checked",
  },
};
</script>

<style scoped lang="scss">
.shared-checkbox {
  display: flex;
  input {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      color: var(--liff-primary_text_color);
      font-size: 14px;
      line-height: 1;
    }

    & + label:before {
      content: "";
      margin-right: 8px;
      display: inline-block;
      vertical-align: text-top;
      width: 18px;
      height: 18px;
      border: 1px solid var(--liff-button-color);
      border-radius: 4px;
      background: white;
    }

    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:checked + label:before {
      background: var(--liff-button-color);
    }

    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    &:checked + label:after {
      content: "";
      position: absolute;
      left: 4px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
        4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      transform: rotate(40deg);
    }
  }
}
</style>
